import React from "react"

import { TIME } from "Components/DatePicker/constants"
import { checkEditor } from "Components/Editor/constants"

import { SuccessOpenIcon } from "../icons"
import { FormInstance } from "antd/lib/form/Form"
import { InfoData, FormatItem } from "../types"
import moment from "moment"

export enum FormItemNames {
  name = "name",
  graph = "is_graph_visible",
  format = "format",
  duration = "duration",
  lesson_duration = "lesson_duration",
  lesson_assemble = "lesson_assemble",
  lesson_start = "lesson_start",
  lesson_format_description = "lesson_format_description",
  days = "days"
}

export const createModalText = {
  createTitle: `Создание курса`,
  editTitle: `Редактирование курса`,
  create: `Создать курс`,
  edit: `Сохранить`
}

export const createModalFormConstants = {
  url: (id?: number) => `admin/course${id ? `/${id}` : ``}`,
  [FormItemNames.name]: {
    field: FormItemNames.name,
    label: `Наименование курса`,
    placeholder: `Введите название`,
    maxLength: 250
  },
  [FormItemNames.graph]: {
    field: FormItemNames.graph,
    label: `Календарик есть?`,
    placeholder: `Че почем?`,
    options: [
      { value: 1, label: `От пользователя` },
      { value: 0, label: `От admin` }
    ]
  },
  [FormItemNames.format]: {
    field: FormItemNames.format,
    label: `Формат курса`,
    placeholder: `Выберите формат`
  },
  [FormItemNames.duration]: {
    field: FormItemNames.duration,
    label: `Количество дней`,
    placeholder: `Выберите кол-во дней`
  },
  [FormItemNames.lesson_duration]: {
    field: FormItemNames.lesson_duration,
    label: `Длительность урока`,
    placeholder: `Введите кол-во часов в день`,
    suffix: `часов каждый день`
  },
  [FormItemNames.lesson_assemble]: {
    field: FormItemNames.lesson_assemble,
    label: `Время сбора`,
    placeholder: ``
  },
  [FormItemNames.lesson_start]: {
    field: FormItemNames.lesson_start,
    label: `Время начала`,
    placeholder: ``
  },
  [FormItemNames.lesson_format_description]: {
    field: FormItemNames.lesson_format_description,
    label: ``,
    placeholder: ``
  },
  [FormItemNames.days]: {
    field: FormItemNames.days,
    label: ``,
    placeholder: ``
  }
}

export const formatOptions = [
  { label: `в обучающем центре`, value: FormatItem.center },
  { label: `дистанционный`, value: FormatItem.remotely }
]
export const dayOptions = [
  { label: `1-но дневный`, value: 1 },
  { label: `2-х дневный`, value: 2 },
  { label: `3-х дневный`, value: 3 },
  { label: `4-х дневный`, value: 4 },
  { label: `5-ти дневный`, value: 5 }
]
export const getLabelByFormat = (format: number) => {
  if (!format) {
    return ""
  } else if (format === 2) {
    return "Формат обучения в центре"
  } else {
    return "Формат дистанционного обучения"
  }
}

export const createApproveText = {
  title: `Создать курс`,
  description: [
    `Вы точно хотите опубликовать курс?`,
    `После публикации, курс станет доступен для записи`
  ],
  cancelText: `Отменить`,
  approveText: `Да, создать`,
  successIcon: <SuccessOpenIcon />,
  successText: [`Отлично! Курс создан`, `и скрыт для записи`]
}

export const editApproveText = {
  title: `Редактирование курса`,
  description: [`Вы точно хотите сохранить изменения`, `в курсе`],
  cancelText: `Отменить`,
  approveText: `Да, сохранить`,
  successIcon: <SuccessOpenIcon />,
  successText: [`Отлично!`, `Изменения в курсе сохранены`]
}

export const checkValidate = (currentForm: FormInstance<any>) => {
  const values = currentForm.getFieldsValue()
  const checkDays = values.days
    ? !!(values.days || []).filter((item?: string) => {
        return checkEditor(item)
      }).length
    : true

  return (
    !values.name ||
    !values.format ||
    !values.duration ||
    !values.lesson_duration ||
    !values.lesson_assemble ||
    !values.lesson_start ||
    checkDays
  )
}

const requiredText = `Необходимо заполнить поле`

export const rules = {
  required: [
    {
      validator: <T, D>(_: T, value: D) =>
        value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(requiredText))
    }
  ]
}

export const newInitialValues = (initial?: InfoData) => {
  return {
    description: initial?.description || null,
    is_graph_visible: initial?.is_graph_visible ? 1 : 0,
    duration: initial?.duration || null,
    format: initial?.format || null,
    days: initial?.days || null,
    lesson_assemble: initial
      ? moment(initial?.lesson_assemble, TIME)
      : undefined,
    lesson_duration: initial?.lesson_duration || null,
    lesson_start: initial ? moment(initial?.lesson_start, TIME) : undefined,
    name: initial?.name || null,
    lesson_format_description: initial?.lesson_format_description || undefined
  }
}
